import React from 'react';
import { Dropdown, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';

import ProfileMenu from '../../menu/ProfileMenu';
import CalendarMenu from '../../menu/CalendarMenu';
import PrintTemplateMenu from '../../menu/PrintTemplateMenu';
import LogoutMenu from '../../menu/LogoutMenu';
import ProjectMenu from '../../menu/ProjectMenu';
import { CalendarMenuContextProvider } from '../../../contexts/app/CalendarMenuContext';
// import ResourcesMenu from '../../resources/ResourcesMenu';
import AuthService from '../../../services/auth.service';
import ProgramMenu from '../../menu/ProgramMenu';
import PreferencesMenu from '../../menu/PreferencesMenu';
import UserTeamManagementMenu from '../../menu/program-users/UserTeamManagementMenu';
import NeedsMenu from '../../needs/NeedsMenu';
import ReportProblemMenu from '../../menu/ReportProblemMenu';

const AppMenu = () => {
    const { isAdmin, readOnly } = AuthService.getCurrentUser();
    const programInfo = JSON.parse(localStorage.getItem('prg'));
    
    const menu = (
        <Menu className="app-menu-menu">
            <ProfileMenu key="profile" />
            <PreferencesMenu key="userPref" />
            {!readOnly && (
                <>
                    <CalendarMenuContextProvider>
                        <CalendarMenu key="calendar" />
                    </CalendarMenuContextProvider>
                {/* </> */}
                </>
            )}
            {isAdmin && !readOnly && (
                <>
                <NeedsMenu isPlanning={false} />
                    <UserTeamManagementMenu key="userteam" /> <ProjectMenu key="project" />
                </>
            )}
            {!readOnly && <PrintTemplateMenu key="print_template" />}
            <ProgramMenu key="programs" />
            <ReportProblemMenu key='report_problem'/>
            <LogoutMenu key="logout" />
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']} className="app-menu-dropdown">
            <a className="ant-dropdown-link" onClick={(event) => event.preventDefault()}>
                <span className="selected-menu-title">
                    <span className="selected-menu-bg">
                        <FontAwesomeIcon icon={faUniversity} />
                    </span>
                    <span className='truncate' title={programInfo?.name ?? 'Planning'}>{programInfo?.name ?? 'Planning'}</span>
                </span>
                <DownOutlined />
            </a>
        </Dropdown>
    );
};

export default AppMenu;
